import React from "react"
import { useUser } from "@contexts/user"
import { followUser, unFollowUser } from "@actions/user"
import { useQuery } from "react-query"
import { Box } from "grommet"
import { useLocalization } from "gatsby-theme-i18n"
import { useTranslation } from "react-i18next"
import { Button, Loader, Avatar, DropButtons } from "@bw/bits"
import { ArtistTeaser } from "@bw/partials"
import { toast } from "react-toastify"
import styled from "styled-components"
import axios from "axios"

const handleUnFollow = (user, dispatch, t) =>
  unFollowUser(user.address)
    .then(response => {
      if (!response.unfollowed) {
        throw new Error("Could not unfollow")
      }
      toast.success(t("user.toastUnfollowSuccess"))
      dispatch({
        type: "UNFOLLOW_USER",
        address: user.address,
      })
    })
    .catch(function () {
      toast.error(t("user.toastUnfollowFail"))
    })

const handleFollow = (user, dispatch, t) =>
  followUser(user.address)
    .then(response => {
      if (!response.followed) {
        throw new Error("Could not follow")
      }
      toast.success(t("user.toastFollowSuccess"))
      dispatch({
        type: "FOLLOW_USER",
        profile: user,
      })
    })
    .catch(function () {
      toast.error(t("user.toastFollowFail"))
    })

const TopUsers = () => {
  const [userCtx, dispatch] = useUser()
  const { locale, localizedPath, defaultLang } = useLocalization()
  const [filter, setFilter] = React.useState("artists")
  const [users, setUsers] = React.useState({ buyers: [], artists: [] })
  const { t } = useTranslation()

  const { data, isError, isLoading, isSuccess } = useQuery("topUsers", () => {
    return axios
      .get("/api/stats/top-customers")
      .then(response => ({
        buyers: response.data.data.top_buyers.map(user => ({
          ...user.buyer,
          total: user.total,
        })),
        artists: response.data.data.top_sellers.map(user => ({
          ...user.seller,
          total: user.total,
        })),
      }))
      .then(data => {
        setUsers(data)
      })
  })

  return (
    <div>
      <Box
        gap="medium"
        direction="row"
        style={{ marginTop: "40px" }}
        justify="start"
      >
        <DropButtons
          label="Top Artists"
          subtitle={t("category")}
          style={{ width: "126px" }}
          multiple={false}
          onChange={(_, select) => {
            setFilter(select.value)
          }}
          categories={[
            {
              active: filter === "artists",
              value: "artists",
              name: "Top Artists",
            },
            {
              active: filter === "buyers",
              value: "buyers",
              name: "Top Buyers",
            },
          ]}
        />
      </Box>

      <Box>
        {isLoading ? (
          <Loader />
        ) : (
          <Grid>
            {users[filter].map((user, i) => {
              const isMe = user.address === userCtx?.publicKey
              const userFollowsThisUser =
                userCtx.loggedIn &&
                userCtx.data.followings.find(
                  following => following.address === user.address
                )

              return (
                <Snap>
                  <ArtistTeaser
                    key={user.address}
                    to={localizedPath({
                      defaultLang,
                      locale,
                      path: `/user/${user.address}/`,
                    })}
                    avatar={
                      user.avatar_url ? (
                        <Avatar
                          image={user.avatar_url}
                          size="medium"
                          dropshadow="without"
                        />
                      ) : null
                    }
                    button={
                      userCtx.loggedIn && !isMe ? (
                        <Button
                          primary
                          label={
                            userFollowsThisUser
                              ? t("user.buttonUnfollow")
                              : t("user.buttonFollow")
                          }
                          icon={userFollowsThisUser ? null : "PlusIcon"}
                          small
                          onClick={() => {
                            userFollowsThisUser
                              ? handleUnFollow(user, dispatch, t)
                              : handleFollow(user, dispatch, t)
                          }}
                        />
                      ) : null
                    }
                    background={user.avatar_url}
                    color="third"
                    rank={i + 1}
                    pseudo={user.name}
                    sold={user.total}
                  />
                </Snap>
              )
            })}
          </Grid>
        )}
      </Box>
    </div>
  )
}

const Snap = styled.div`
  scroll-snap-align: start;
  padding: 0 15px;
`

const Grid = styled.div`
  display: flex;
  flex-direction: row;
  padding: 40px 0;
  margin: 0 -15px;
  width: calc(100% + 30px);
  overflow-x: auto;
  scroll-snap-type: x mandatory;
`

export default TopUsers

import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Image } from "@bw/bits"
import { breakpoints } from "../../../theme"
import { Timeline as TimelineIcon } from "@bw/icons"

const Flex = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: ${props => (props.inversed ? "row-reverse" : "row")};
  @media (max-width: ${breakpoints.small}px) {
    flex-direction: column;
    align-items: center;
  }
`

const Date = styled.div`
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  @media (max-width: ${breakpoints.small}px) {
    padding-top: 35px;
  }
`

const Title = styled.h3`
  font-weight: 800;
  font-size: 25px;
  line-height: 30px;
  color: #6080e9;
  margin: 10px 0px 20px 0px;
`

const Description = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: #04062c;
`

const Line = styled.div`
  display: flex;
  padding: 190px 0px;
  border-left: 1px solid rgb(4, 6, 44);
  margin: 0px 7vw;
  width: 1px;
  @media (max-width: ${breakpoints.small}px) {
    order: 3;
    padding: 60px 0px;
    margin: 20px 5vw;
  }
`

const Dot = styled.div`
  width: 40px;
  height: 40px;
  background-color: rgb(4, 6, 44);
  border-radius: 50%;
  box-shadow: 0 0 0 20px rgb(4 6 44 / 20%);
  transform: translateX(-20px);
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  @media (min-width: ${breakpoints.xsmall}px) and (max-width: ${breakpoints.medium}px) {
    width: 30px;
    height: 30px;
    box-shadow: 0 0 0 10px rgb(4 6 44 / 20%);
    transform: translateX(-15px);
  }
`

const Text = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: ${breakpoints.small}px) {
    transform: translateY(0px);
    order: 2;
    text-align: center;
    width: 100%;
  }
`

const ImageContainer = styled.div`
  width: 435px;
  width: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
  @media (max-width: ${breakpoints.small}px) {
    width: 100%;
  }
`

const Timeline = ({ events }) => {
  return (
    <div>
      {events.map(({ period, title, description, image, isAPastEvent }, i) => (
        <Flex key={i} inversed={i % 2 !== 0}>
          <ImageContainer>
            {image && (
              <Image image={image.url} alt={image.alt} type="Timeline" />
            )}
          </ImageContainer>
          <Line>
            <Dot>
              {isAPastEvent ? (
                <TimelineIcon />
              ) : (
                <TimelineIcon fillColor="#fff" />
              )}
            </Dot>
          </Line>
          <Text>
            <Date>{period}</Date>
            <Title>{title}</Title>
            <Description>{description}</Description>
          </Text>
        </Flex>
      ))}
    </div>
  )
}

export default Timeline

Timeline.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      period: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.oneOf([
        PropTypes.element,
        PropTypes.shape({
          url: PropTypes.string.isRequired,
          alt: PropTypes.string,
        }),
      ]),
    })
  ),
}

Timeline.defaultProps = {
  events: [],
}

import React from "react"
import PropTypes from "prop-types"
import ReactPlayer from "react-player"
import styled from "styled-components"
import { Box } from "grommet"

const ReactPlayerWrapper = styled(Box)`
  position: relative;
  padding-top: 56.25%;
  aspect-ratio: 16 / 9;
`

const StyledReactPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`
const Video = ({ url, controls, poster, muted, loop }) => {
  return (
    <ReactPlayerWrapper>
      <StyledReactPlayer
        url={url}
        light={poster}
        controls={controls}
        volume={1}
        playing={true}
        muted={muted}
        loop={loop}
        width="100%"
        height="100%"
      />
    </ReactPlayerWrapper>
  )
}

export default Video

Video.propTypes = {
  controls: PropTypes.bool,
  url: PropTypes.string.isRequired,
  poster: PropTypes.string.isRequired,
  muted: PropTypes.bool,
  loop: PropTypes.bool,
}

Video.defaultProps = {
  controls: true,
  muted: false,
  loop: false,
}

import React from "react"
import PropTypes from "prop-types"
import { Heading, Text, ResponsiveContext, Box, Image } from "grommet"
import styled from "styled-components"
import { Link } from "gatsby"
import { breakpoints } from "../../../theme"

const StyledBox = styled(Box)`
  position: relative;
  background: linear-gradient(264.34deg, #04062c 4.39%, #101461 100%);
  border-radius: 17px;
  min-height: 120px;
  padding: 15px;
  &:hover {
    border: none;
  }
  @media (max-width: ${breakpoints.small}px) {
    padding: 10px 0px;
  }
`
const StyledHeading = styled(Heading)`
  font-weight: 800;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.02em;
  text-shadow: 0 0 12px rgba(0, 0, 0, 0.9);
`

const StyledLink = styled(Link)`
  text-decoration: none;
  &:hover {
    border: none;
  }
`

const StyledA = styled.a`
  text-decoration: none;
  &:hover {
    border: none;
  }
`

const StyledCtA = styled.div`
  color: ${props => (props.$textColor === "positive" ? "#000000" : "#ffffff")};
  margin-left: 25px;
  z-index: 2;
  text-shadow: 0px 0px 5px rgba(7, 7, 37, 0.25);
  &:hover {
    border: none;
  }
`
const StyledSuptitle = styled(Text)`
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  opacity: 0.5;
`

const StyledSubtitle = styled(Text)`
  margin-bottom: 25px;
  font-size: 16px;
`

const StyledButton = styled.div`
  background: linear-gradient(
    264.34deg,
    rgba(255, 255, 255, 0.35) 4.39%,
    rgba(218, 220, 251, 0.35) 46.5%,
    rgba(162, 166, 244, 0.35) 100%
  );
  box-shadow: 0px 10px 25px rgba(83, 120, 255, 0.4);
  backdrop-filter: blur(20px);
  border-radius: 7px;
  border: none;
  backdrop-filter: blur(25px);
  font-weight: 800;
  font-size: 11px;
  line-height: 11px;
  display: flex;
  width: fit-content;
  justify-content: center;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  padding: 13px 38px;
  cursor: pointer;
  color: ${props => (props.$textColor === "positive" ? "#000000" : "#ffffff")};
  transition: all 0.2s ease-in-out;

  &:hover {
    border: none;
    background: linear-gradient(
      264.34deg,
      rgba(255, 255, 255, 0.35) 35%,
      rgba(218, 220, 251, 0.35) 70%,
      rgba(162, 166, 244, 0.35) 100%
    );
    box-shadow: 0px 15px 35px rgba(5, 0, 255, 0.25);
  }
`
const StyledImage = styled(Image)`
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  object-fit: contain;
`
const CallToAction = ({
  background,
  suptitle,
  title,
  subtitle,
  buttonLabel,
  to,
  href,
  onClick,
  textColor,
  image,
  alt,
}) => {
  const size = React.useContext(ResponsiveContext)

  const Cta = ({ onClickEvent }) => (
    <StyledBox
      background={background}
      round="small"
      direction="row"
      justify="between"
      align="center"
    >
      <StyledCtA $textColor={textColor}>
        <StyledSuptitle as="div" margin={{ bottom: size }}>
          {suptitle}
        </StyledSuptitle>
        <StyledHeading level={3} margin={{ top: "none" }}>
          {title}
        </StyledHeading>
        <StyledSubtitle as="div" margin={{ bottom: size }}>
          {subtitle}
        </StyledSubtitle>
        {buttonLabel && (
          <StyledButton $textColor={textColor}>{buttonLabel}</StyledButton>
        )}
      </StyledCtA>
      <StyledImage src={image} a11yTitle={alt} />
    </StyledBox>
  )

  switch (true) {
    case typeof to !== "undefined":
      return (
        <StyledLink to={to}>
          <Cta />
        </StyledLink>
      )

    case typeof href !== "undefined":
      return (
        <StyledA href={href} target="_blank" rel="noreferrer">
          <Cta />
        </StyledA>
      )

    case typeof onClick !== "undefined":
      return <Cta onClickEvent={onClick} />

    default:
      return <Cta />
  }
}

export default CallToAction

CallToAction.propTypes = {
  suptitle: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  alt: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string,
  background: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      color: PropTypes.string,
      repeat: PropTypes.oneOf(["repeat", "no-repeat", "repeat-y", "repeat-x"]),
      size: PropTypes.oneOf(["cover", "contain"]),
      image: PropTypes.string,
    }),
  ]),
  to: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  textColor: PropTypes.oneOf(["positive", "negative"]),
}

CallToAction.defaultProps = {
  textColor: "negative",
}

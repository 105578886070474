import React from "react"
import PropTypes from "prop-types"
import { Box } from "grommet"
import { Container } from "@bw/bits"
import styled from "styled-components"
import { mediaQuery } from "../../../theme.js"

const StyledSection = styled.div`
  background-image: url(${props => props.image});
  background-size: cover;
  width: 100%;
  height: 80vh;
  margin-bottom: 60px;
  display: flex;
  align-items: center;

  ${mediaQuery("small")`
      margin-bottom: 75px;
  `}
`

const StyledHeading = styled.div`
  font-weight: 400;
  letter-spacing: 0.18em;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 1.22;
  margin-bottom: 30px;
  color: ${props => (props.textColor === "positive" ? "#000000" : "#ffffff")};

  ${mediaQuery("small")`
    font-size: 22px;
    margin-bottom: 45px;
  `}
`

const Title = styled.h1`
  font-weight: 700;
`

const Hero = ({
  suptitle,
  title,
  subtitle,
  containerSize,
  textColor,
  buttons,
  buttonsPosition,
  image,
}) => {
  return (
    <StyledSection image={image}>
      <Container size={containerSize}>
        <StyledHeading level={2} textColor={textColor}>
          {suptitle}
          <Title>{title}</Title>
          {subtitle}
        </StyledHeading>
        {buttons && (
          <Box
            direction="row"
            justify={buttonsPosition}
            margin={{ top: "45px" }}
            gap="10px"
          >
            {buttons}
          </Box>
        )}
      </Container>
    </StyledSection>
  )
}

export default Hero

Hero.propTypes = {
  textColor: PropTypes.oneOf(["positive", "negative"]),
  suptitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  buttons: PropTypes.element,
  image: PropTypes.string.isRequired,
  buttonsPosition: PropTypes.oneOf(["start", "center", "end"]),
  containerSize: PropTypes.oneOf(["small", "medium", "large"]),
}

Hero.defaultProps = {
  buttonsPosition: "start",
  containerSize: "large",
  textColor: "positive",
}

import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { useNfts } from "@contexts/nft"
import { HighlightTeaser, NftTeaser } from "@bw/partials"
import { Loader } from "@bw/bits"
import { useTranslation } from "react-i18next"
import { getArtistOfTheDay } from "@actions/user"
import { useQuery } from "react-query"
import { useLocalization } from "gatsby-theme-i18n"
import { breakpoints } from "../../../theme"

const Grid = styled.div`
  display: grid;
  gap: 48px;

  @media (min-width: ${breakpoints.large}px) {
    grid-template-columns: 1fr 1fr;
    gap: 35px;
  }
`

const NftGrid = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;

  @media (min-width: ${breakpoints.large}px) {
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr 1fr;
    gap: 35px;
  }

  > * {
    flex: 0 1 240px;
  }
`

const ArtistOfTheDay = ({}) => {
  const { t } = useTranslation()
  const { locale, localizedPath, defaultLang } = useLocalization()
  const [nfts] = useNfts()
  const artistOfTheDayQuery = useQuery(["artist_today"], () => {
    return getArtistOfTheDay().then(response => {
      return {
        ...response,
        tokens: response.tokens.map(token => {
          const listedNft = nfts.listed.find(
            listed => listed.nft === token.address
          )
          return {
            ...token,
            price: listedNft ? listedNft.price : null,
          }
        }),
      }
    })
  })

  return (
    <>
      {artistOfTheDayQuery.isLoading && (
        <Loader
          messages={[
            t("loading.theNft"),
            t("loading.itTakesLongerThanExpected"),
          ]}
          treshold={5}
        />
      )}
      {artistOfTheDayQuery.isSuccess && (
        <Grid responsive columns={2}>
          <HighlightTeaser
            imageType="ArtistHighlight"
            title={t("index.artist")}
            subtitle={"@" + artistOfTheDayQuery.data.artist.name}
            image={artistOfTheDayQuery.data.artist.avatar_url}
            footer={
              <>
                {t("user.xFollowers", {
                  count: artistOfTheDayQuery.data.artist.followers_count,
                })}
                <br />
                {t("user.xFollows", {
                  count: artistOfTheDayQuery.data.artist.followings_count,
                })}
              </>
            }
            to={localizedPath({
              defaultLang,
              locale,
              path: `/user/${artistOfTheDayQuery.data.artist.address}/`,
            })}
            excerpt={artistOfTheDayQuery.data.artist.bio}
          />
          <NftGrid>
            {artistOfTheDayQuery.data.tokens.slice(0, 2).map(token => (
              <NftTeaser
                key={token.address}
                image={token.image}
                name={token.name}
                artist={artistOfTheDayQuery.data.artist.name}
                price={token.price}
                likes={token.likes_count}
                to={localizedPath({
                  defaultLang,
                  locale,
                  path: `/nft/${token.address}/`,
                })}
              />
            ))}
          </NftGrid>
        </Grid>
      )}
    </>
  )
}

export default ArtistOfTheDay

ArtistOfTheDay.propTypes = {
  surname: PropTypes.string,
  bio: PropTypes.string,
  link: PropTypes.string,
}

import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { mediaQuery } from "../../../theme.js"

const Wrapper = styled.div`
  display: grid;
  grid-gap: 32px;

  ${mediaQuery("medium")`
    grid-template-columns: 1fr 1fr
  `}
`

const InversedText = styled.div`
  ${mediaQuery("medium")`
  grid-row: 1 / 2;
    grid-column: 2 / 3;
  `}
`

const InversedImage = styled.div`
  ${mediaQuery("medium")`
  grid-row: 1 / 2;
    grid-column: 1 / 2;
  `}
`

const ContentWithImage = ({ text, image, orientation }) => {
  if (orientation === "text_on_right") {
    return (
      <Wrapper>
        <div>{text}</div>
        <div>{image}</div>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <InversedText>{text}</InversedText>
      <InversedImage>{image}</InversedImage>
    </Wrapper>
  )
}

export default ContentWithImage

ContentWithImage.propTypes = {
  text: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  orientation: PropTypes.oneOf(["text_on_left", "text_on_right"]),
}

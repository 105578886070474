import React from "react"
import PropTypes from "prop-types"
import { Grid, Heading, ResponsiveContext } from "grommet"

const Columns = ({ count, contents, children }) => {
  const size = React.useContext(ResponsiveContext)

  let layout = ["1fr"]

  if (count === 4) {
    layout = size === "xlarge" ? ["1fr", "1fr", "1fr", "1fr"] : ["1fr", "1fr"]
  }

  if (count === 3) {
    layout = size === "large" ? ["1fr", "1fr", "1fr"] : ["1fr", "1fr"]
  }

  if (count === 2) {
    layout = ["1fr", "1fr"]
  }

  if (size === "small") {
    layout = ["1fr"]
  }

  return (
    <Grid columns={layout} gap={size}>
      {children ||
        contents.map((item, i) => (
          <div key={i}>
            <Heading level={3}>{item.title}</Heading>
            <div>{item.content}</div>
          </div>
        ))}
    </Grid>
  )
}

export default Columns

Columns.propTypes = {
  count: PropTypes.number,
  contents: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.string,
    })
  ),
}

import React from "react"
import PropTypes from "prop-types"
import { Grid, Image } from "grommet"
import { Gallery as PhotoSwipe, Item } from "react-photoswipe-gallery"
import styled, { createGlobalStyle } from "styled-components"

const ImageStyle = createGlobalStyle`
  .pswp__img {
    object-fit: contain
  }
`

const StyledImage = styled(Image)`
  aspect-ratio: 16 / 9;
  object-fit: cover;
  width: 100%;
  cursor: pointer;
`

const Gallery = ({ images }) => {
  return (
    <PhotoSwipe
      shareButton={false}
      fullscreenButton={false}
      options={{
        hideAnimationDuration: 0,
        showAnimationDuration: 0,
      }}
    >
      <ImageStyle />
      <Grid columns={["1fr", "1fr", "1fr"]} rows="">
        {images.map(image => (
          <Item
            original={image.image}
            thumbnail={image.thumbnail}
            width="1024"
            height="768"
          >
            {({ ref, open }) => (
              <StyledImage ref={ref} onClick={open} src={image.thumbnail} />
            )}
          </Item>
        ))}
      </Grid>
    </PhotoSwipe>
  )
}

export default Gallery

Gallery.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      thumbnail: PropTypes.string,
      image: PropTypes.string,
    })
  ),
}

import React from "react"
import PropTypes from "prop-types"

const Content = ({ text }) => {
  return <div>{text}</div>
}

export default Content

Content.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
}

import React from "react"
import PropTypes from "prop-types"
import { Image as GrommetImage } from "grommet"
import styled from "styled-components"

const TeaserWrapperImage = styled.div`
  height: 500px;
  width: 100%;
`

const Image = ({ imageUrl, alt, legend, display }) => {
  const imageMode = display === "fluid" ? "contain" : "cover"

  return (
    <>
      <TeaserWrapperImage width={display}>
        <GrommetImage fill fit={imageMode} src={imageUrl} a11yTitle={alt} />
      </TeaserWrapperImage>
      <p>{legend}</p>
    </>
  )
}

export default Image

Image.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  display: PropTypes.oneOf([
    "small",
    "normal",
    "fluid",
    "navigator window",
    "large",
  ]),
  legend: PropTypes.string,
  alt: PropTypes.string.isRequired,
}

Image.defaultProps = {
  display: "normal",
}
